import React from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';

const ScrollLink = ({ text, children, to, classes, styles, offset }) => (
	<Component
		to={to}
		className={classes}
		href="/"
		spy={true}
		smooth={true}
		duration={600}
		offset={offset || -75}
		styles={styles}
	>
		{text}
		{children}
	</Component>
);

export default ScrollLink;

const Component = styled(Link)`
	${props => props?.styles}
`;
